<template>
  <div v-if="user.profile.admin">
    <v-select
      :items="models"
      label="Models"
      @change="getCollection($event)"
    ></v-select>

    <div>
      <v-row>
        <v-col
          cols="3"
          class="offset-md-4"
        >
          <v-text-field
            v-model="fighterId"
            label="Fighter ID"
            @change="updateFighterId"
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="3"
        >
          <v-btn
            color="primary"
            x-small
            class="float-left mt-5"
            @blur="addUpdateFighter"
          >
            Add/Update Fighter
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <TeamTable
      :fighters="jsonData"
      :marktet-place-view="true"
      :loading-table="loadingTable"
      :item-key="itemKey"
    ></TeamTable>

    <hr>
    <v-select
      :items="models"
      label="Folder"
      @change="getCollection($event)"
    ></v-select>
    <v-file-input
      ref="refInputEl"
      type="file"
      accept="image/*"
      label="File upload"
      outlined
      dense
      @change="previewImage($event)"
    ></v-file-input>
    <v-btn
      class="text-center d-all mx-5 my-5"
      color="success"
      @click="upload"
    >
      Upload
    </v-btn>
    <v-img
      v-if="imagePreview"
      :src="imagePreview"
      lazy-src="https://www.fantasykombat.com/favicon-32x32.png"
    ></v-img>
  </div>
</template>
<script>
import firebase from 'firebase/app'
import axios from 'axios'
import db from '@/db/init'
import TeamTable from '@/views/components/TeamTable.vue'

export default {
  components: {
    TeamTable,
  },
  data() {
    return {
      filter: null,
      user: this.$store.state.user,
      loadingTable: false,
      selectedModel: '',
      fighterId: '',
      itemKey: 'mintedTokenId',
      models: [
        'advisors',
        'mintedFighters',
        'fighters',
        'teams',
        'ufcEvents',
        'profiles',
      ],
      jsonData: [],
      imagePreview: '',
      file: '',
    }
  },
  methods: {
    async getCollection(event) {
      this.loadingTable = true
      this.selectedModel = event
      this.filter = this.$store.state.user.uid
      const data = []
      await db.collection(event)
        .get()
        .then(querySnapshot => {
          this.loadingTable = false
          querySnapshot.forEach(doc => {
            data.push(doc.data())
          })
        })
        .catch(error => {
          this.loadingTable = false
          console.log('Error getting documents: ', error)
        })
      this.jsonData = data
    },
    previewImage(e) {
      console.log(e)
      // eslint-disable-next-line prefer-destructuring
      this.file = e
      this.preview = URL.createObjectURL(this.file)
      this.imagePreview = this.preview
    },
    upload() {
      const storage = firebase.storage().ref()
      const storageRef = storage.child(`${this.selectedModel || 'images'}/${new Date().getTime()}-${this.file.name}`)
      const task = storageRef.put(this.file)

      // update progress bar
      task.on('state_changed',
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(`Upload is ${progress} % done`)
          // eslint-disable-next-line default-case
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING:
              console.log('Upload is running')
              break
            default:
          }
        },
        error => {
          console.log(error)
        },
        () => {
          // Handle successful uploads on complete
          task.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log('File available at', downloadURL)
          })
        })
    },

    updateFighterId(e) {
      this.fighterId = e
    },
    addUpdateFighter() {
      console.log('update fighter')
      const api = axios.create({
        baseURL: this.$baseURL,
        timeout: 10 * 60 * 1000, // whatever time you want
      })
      api.get(`fighter/?id=${this.fighterId}`).then(response => {
        console.log(response)
        this.fighterId = ''
      })
    },
  },
}
</script>
